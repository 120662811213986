import axios, { AxiosError, AxiosResponse } from "axios";
import { Termin } from "@/models/models";

// https://gist.github.com/JaysonChiang/fa704307bacffe0f17d51acf6b1292fc

// axios.defaults.baseURL = "http://localhost:3000";
axios.defaults.baseURL = "http://api.gennebreck.info/index.php/";
// axios.defaults.baseURL = "http://localhost:3000/api";

// axios.interceptors.request.use((config) => {
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.defaults.headers.put;

axios.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    const { data, status } = error.response!;
    switch (status) {
      case 400:
        console.error(data);
        break;

      case 401:
        console.error("unauthorised");
        break;

      case 404:
        console.error("/not-found");
        break;

      case 500:
        console.error("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: Record<string, unknown>) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: Record<string, unknown>) =>
    axios.put<T>(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url),
};

const eventsPath = "events";
const events = {
  list: () => request.get<Termin[]>(`/${eventsPath}`),
  details: (id: number) => request.get<Termin>(`/${eventsPath}/${id}`),
  create: (data: Termin) => request.post<Termin>(`/${eventsPath}`, data),
  update: (id: number, data: Termin) =>
    request.put<Termin>(`/${eventsPath}/${id}`, data),
  delete: (id: number) => request.delete(`/${eventsPath}/${id}`),
};

const api = {
  events,
};

export default api;
