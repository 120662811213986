
import { defineComponent, ref } from "vue";
import router from "@/router";
import { useTerminStore, eventsMem } from "@/store/termine";
import { Termin } from "@/models/models";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  name: "TerminEdit",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const $q = useQuasar();
    const { termine, loading } = storeToRefs(useTerminStore());
    const {
      fetchTermine,
      getTermin,
      updateTermin,
      createTermin,
      deleteTermin,
    } = useTerminStore();
    fetchTermine();

    const formTermin = ref({} as Termin);
    formTermin.value.publish = true;

    return {
      showNotif() {
        $q.notify({
          message: "Termin gespeichert",
        });
      },
      loading,
      getTermin,
      termine,
      updateTermin,
      createTermin,
      deleteTermin,
      formTermin,
      model: ref(null),
      alert: ref(false),
      required: [
        (val: string) =>
          (val && val.length > 0) || "Feld muss ausgefüllt werden.",
      ],
    };
  },
  computed: {
    isCreationMode() {
      return this.id === "create";
    },
  },
  methods: {
    onBack() {
      router.push({ name: "admin" });
    },
    terminClone() {
      if (!this.isCreationMode && Object.keys(this.formTermin).length <= 1)
        this.formTermin = cloneDeep(this.getTermin(Number(this.id)));
      return this.formTermin;
    },
    async onSubmit() {
      eventsMem.clear();
      if (!this.isCreationMode)
        await this.updateTermin(+this.id, this.formTermin);
      else {
        const id = await this.createTermin(this.formTermin);
        await router.replace({ name: "edit", params: { id: id } });
      }
      this.showNotif();
    },
    async onDelete() {
      eventsMem.clear();
      await this.deleteTermin(+this.id);
      await router.push({ name: "admin" });
    },
  },
});
