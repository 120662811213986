import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import { createPinia } from "pinia";
import v3ImgPreview from "v3-img-preview";

/* eslint-disable */
// @ts-ignore
import quasarUserOptions from "./quasar-user-options";
/* eslint-enable */

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(v3ImgPreview, { showToolbar: false })
  .use(createPinia())
  .mount("#app");
