import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TerminEdit from "../views/TerminEdit.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dorfblatt",
    name: "dorfblatt",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DorfblattView.vue"),
  },
  {
    path: "/admin45549",
    name: "admin",
    component: () => import("../views/AdminView.vue"),
  },
  {
    path: "/edit45549/:id",
    name: "edit",
    props: true,
    component: TerminEdit,
  },
  {
    path: "/impressum",
    name: "impressum",
    props: true,
    component: () => import("../views/ImpressumView.vue"),
  },
  {
    path: "/veranstalter",
    name: "veranstalter",
    component: () => import("../views/VeranstalterView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
