import { defineStore } from "pinia";
import { Termin } from "@/models/models";
import api from "@/services/eventService";
import { useMemoize } from "@vueuse/core";

export const eventsMem = useMemoize(async () =>
  api.events.list().then((data) => {
    data.forEach(
      (e) =>
        (e.startDateTime = new Date(
          e.startDate + " " + (e.startTime ? e.startTime : "01:00")
        ))
    );

    data.sort(function (a: Termin, b: Termin) {
      return a.startDateTime.valueOf() - b.startDateTime.valueOf();
    });
    return data;
  })
);

export const useTerminStore = defineStore({
  id: "termin",
  state: () => ({
    termine: [] as Termin[],
    post: null,
    loading: false,
    error: false,
  }),
  getters: {
    getTermin: (state) => {
      return (terminId: number): Termin => {
        const test = state.termine.find((t: Termin) => t.id == terminId);
        if (!test) throw "Error";
        return test;
      };
    },
  },
  actions: {
    async fetchTermine() {
      if (this.termine.length !== 0) return;
      this.termine = [];
      this.loading = true;
      try {
        this.termine = await api.events.list();
        this.termine.map(
          (e) =>
            (e.startDateTime = new Date(
              e.startDate + " " + (e.startTime ? e.startTime : "01:00")
            ))
        );
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async updateTermin(id: number, termin: Termin) {
      const response = await api.events.update(id, termin);
      response.startDateTime = new Date(
        response.startDate +
          " " +
          (response.startTime ? response.startTime : "01:00")
      );
      const listId = this.termine.findIndex((e) => e.id == response.id);
      this.termine[listId] = response;
    },
    async createTermin(termin: Termin) {
      const response = await api.events.create(termin);
      response.startDateTime = new Date(
        response.startDate +
          " " +
          (response.startTime ? response.startTime : "01:00")
      );
      this.termine.push(response);
      return response.id;
    },
    async deleteTermin(id: number) {
      await api.events.delete(id);
      this.termine = this.termine.filter((e) => e.id !== id);
    },
  },
});
