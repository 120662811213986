
import { defineComponent, Ref } from "vue";
import { eventsMem } from "@/store/termine";
import { v3ImgPreviewFn } from "v3-img-preview";
import { Termin } from "@/models/models";
import { asyncComputed } from "@vueuse/core";
import VeranstalterComponent from "@/components/VeranstalterComponent.vue";

// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: { VeranstalterComponent },
  setup() {
    const published: Ref<Termin[]> = asyncComputed(async () => {
      const data = await eventsMem();
      return data
        .filter((event: Termin) => event.publish)
        .filter((e) => {
          let dayBefore = new Date();
          dayBefore.setDate(dayBefore.getDate() - 5);

          if (e.endDate && e.endDate !== "0000-00-00") {
            return Date.parse(e.endDate).valueOf() > dayBefore.valueOf();
          }
          return e.startDateTime.getTime() > dayBefore.getTime();
        });
    }, []);

    const handleClick = (src: string) => {
      v3ImgPreviewFn(src);
    };
    return { handleClick, published };
  },
  // components: {
  //   HelloWorld,
  // },
});
