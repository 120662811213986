
import { useRouter } from "vue-router";
import { defineComponent, computed, ref } from "vue";
import router from "@/router";

const menuList = [
  {
    icon: "event",
    label: "Termine",
    to: "/",
    separator: false,
    desktop: true,
  },
  {
    icon: "newspaper",
    label: "Dorfblatt",
    to: "/dorfblatt",
    separator: false,
    desktop: true,
  },
  {
    icon: "groups",
    label: "Veranstalter",
    to: "/veranstalter",
    separator: false,
    desktop: false,
  },
  {
    icon: "contact_page",
    label: "Impressum",
    to: "/impressum",
    separator: false,
    desktop: true,
  },
];

export default defineComponent({
  name: "HomeView",
  setup() {
    const currentRoute = computed(() => {
      return useRouter().currentRoute.value.name;
    });
    return {
      currentRoute,
      drawer: ref(false),
      menuList,
    };
  },
  methods: {
    onLogin() {
      router.push({ name: "admin" });
    },
  },
});
